<template>
    <div class="content">
        <div class="brand">
            <div>
                <img src="../assets/kbi.png" class="logo-top" />
            </div>
            <div>
                CMS
                <p>Clearinghouse Management System</p>
            </div>
        </div>
        <div class="wrap-login">
            <div class="title">Log in</div>
            <b-alert variant="danger" :show="statusLogin == 2023 || statusLogin == 2018">Username atau Password salah</b-alert>
            <b-alert variant="danger" :show="status == 404">Akun tidak terdaftar</b-alert>
            <form @submit.prevent="login">
                <div class="form-group">
                    <span><i class="fa fa-user"></i></span>
                    <input type="text" class="form-input" placeholder="username" v-model="form.username" />
                </div>
                <div class="form-group">
                    <span><i class="fa fa-lock"></i></span>
                    <input type="password" class="form-input" placeholder="password" v-model="form.password" />
                </div>
                <button type="submit" class="btn-login">
                    <span v-show="!isLoading">Login</span>
                    <Loader v-show="isLoading" />
                </button>
            </form>
        </div>
        <div class="powered"></div>
    </div>
</template>

<script>
// import axios from "axios";
import Loader from "../components/Loader.vue";
import sha256 from "sha256";
export default {
    components: {
        Loader,
    },
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            isLoading: false,
            status: "",
            scriptCaptcha: "",
        };
    },
    methods: {
        login() {
            this.isLoading = true;
            window.grecaptcha.enterprise.ready(async () => {
                const tokenCaptcha = await window.grecaptcha.enterprise.execute("6LcOUPckAAAAAHXPk2_ORUtBQK0u3aKSti-9UUSf", { action: "LOGINKLIRING" });
                let data = {
                    username: this.form.username,
                    password: sha256(this.form.password),
                    clientCaptchaKey: tokenCaptcha,
                };
                this.$store
                    .dispatch("LOGIN", data)
                    .then(() => {
                        const role = sessionStorage.getItem("role");
                        if (role == 1 || role == 2 || role == 4) {
                            window.location.href = "/";
                        } else if (role == 6) {
                            window.location.href = "/admin-pos";
                        } else if (role == 8) {
                            window.location.href = "/bappebti-inventory";
                        } else {
                            window.location.href = "/operator-deposite";
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.log(err.response);
                    });
            });
        },
    },
    computed: {
        statusLogin() {
            let status = this.$store.state.status_login;
            return status;
        },
    },
    created() {
        this.scriptCaptcha = document.createElement("script");

        // this.scriptCaptcha.src = "https://www.google.com/recaptcha/enterprise.js?render=6LdkqcUkAAAAAGwOMHZdqguhNkd2ktVfiD7DDRkV"; // dev
        this.scriptCaptcha.src = "https://www.google.com/recaptcha/enterprise.js?render=6LcOUPckAAAAAHXPk2_ORUtBQK0u3aKSti-9UUSf"; // prod
        this.scriptCaptcha.async = true;

        document.body.appendChild(this.scriptCaptcha);
    },
    beforeDestroy() {
        document.body.removeChild(this.scriptCaptcha);
    },
};
</script>

<style scoped>
.content {
    background: radial-gradient(#1e1e2f, #1e1e24);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wrap-login {
    background: #f8f9fe;
    width: 35%;
    border-radius: 5px;
    padding: 20px;
}

.logo-top {
    width: 3em;
}

.brand {
    color: #a5a5a5;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 1.5rem;
}

.brand p {
    font-size: 13px;
    margin: -12px 0 25px;
}

.title {
    margin-bottom: 30px;
    font-size: 30px;
}

.form-group {
    position: relative;
    margin-bottom: 15px;
}

.form-group span {
    position: absolute;
    background: #ffffff;
    border: inherit;
    text-align: center;
    padding: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    top: 1px;
    left: 1px;
    width: 2.8em;
    color: rgba(29, 37, 59, 0.5);
}

.form-input {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 8px 3.6em;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.form-input:focus {
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.btn-login {
    width: 100%;
    background-color: #5e72e4;
    border-color: #5e72e4;
    border: none;
    border-radius: 5px;
    padding: 11px;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-login:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}

.powered {
    width: 50%;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.title-powered {
    color: #9e9e9e;
}

.wrap-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.img-abi {
    width: 15%;
}

.img-abx {
    width: 9%;
}

.img-pos {
    width: 10%;
}

@media only screen and (max-width: 600px) {
    .wrap-login {
        width: 87%;
    }
}
</style>
